import Router from "./router/route_generate";

async function main() {
    let crrLanguage = readCookie("__appLang") || "th";

    window.__langData = await (
        await fetch("/i18n/" + crrLanguage + ".json")
    ).text();
    window.__langData = JSON.parse(window.__langData);
    window.__ = (key) => {
        return window.__langData[key] || key;
    };

    window.__APP = {
        apiUrl: import.meta.env.VITE_API_URL ?? "http://localhost:4004/"
    }
    Router();
}

main();

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function setCooike(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
