
import RouteGuard from "./route_guard";
import loadHTML from "../utils/loadHTML";
import Routers from './router'

const error404 = {
    name: "404",
    htmlPath: "404",
    cssPath: [],
};

export default async function () {
    // Lazy load view element:
    var content = null || document.getElementById("main-page");

    // Get the parsed URl from the addressbar
    let request = parseRequestURL();
    // Parse the URL and if it has an id part, change it with the string ":id"
    let parsedURL =
        (request.resource ? "/" + request.resource : "/") +
        (request.id ? "/:id" : "") +
        (request.verb ? "/" + request.verb : "");
    // Get the page from our hash of supported routes.
    // If the parsed URL is not in our list of supported routes, select the 404 page instead
    let page = Routers[parsedURL] ? Routers[parsedURL] : error404;
    window.__APP.page = page.name;
    if(page.name == "formsView" || page.name == "formResponse" || page.name == "formsInactive") {
        var originalElement = document.getElementById("main-page");
        var copiedElement = originalElement.cloneNode(true); // true indicates deep cloning
        var copyContainer = document.body;
        copyContainer.innerHTML = ""; // Clear container
        copyContainer.appendChild(copiedElement);
        var mainPage = document.getElementById("main-page");
        mainPage.style.overflowY = "hidden";
        mainPage.style.height = "unset";
        mainPage.style.opacity = "0";
        
        var body = document.body;
        body.style.height = "unset";
        body.style.overflowY = "auto";
        body.style.backgroundColor = "#DDE8FC";
        body.style.minHeight = "100%";

        var html = document.documentElement;
        html.style.overflowY = "auto";

        content = null || document.getElementById("main-page");
    }

    
    const timestamp = new Date().getTime();
    const randomNumber = Math.floor(Math.random() * 1000) + timestamp;

    let res = await RouteGuard(page);
    if (!res.next) return;

    if (page.cssPath) {
        page.cssPath.forEach((element) => {
            addCss(`/css/${element}.css?v=${randomNumber}`);
        });
    }

    const template = await loadHTML(
        `../pages/${page.htmlPath}.html?v=${randomNumber}`,
        import.meta.url
    );

    let view = /*html*/ `
            ${template}
    `;
    content.innerHTML = view;

    if(!["login","404",""].includes(page.name)) {
        addFooter();
    }

    if (page.jsPath) {
        page.jsPath.forEach((element) => {
            addJs(`/js/${element}.js?v=${randomNumber}`);
        });
    }
    addJs(`/js/common.js?v=${randomNumber}`);

}

function addFooter() {
    const timestamp = new Date().getTime();
    const randomNumber = Math.floor(Math.random() * 1000) + timestamp;
    // Create the footer element
    const footer = document.createElement('footer');
    footer.classList.add('footer');

    // Create the anchor element
    const anchor = document.createElement('a');
    anchor.classList.add('powered-by');
    anchor.href = 'https://www.orisma.com/';
    anchor.target = '_blank';

    // Create the div element for the logo
    const logoDiv = document.createElement('div');
    logoDiv.classList.add('orisma-logo');

    // Create the image element for the logo
    const logoImg = document.createElement('img');
    logoImg.src = '/image/logo/orisma.png?v=' + randomNumber;

    // Append the image element to the logo div
    logoDiv.appendChild(logoImg);

    // Create the span element for the text
    const spanText = document.createElement('span');
    spanText.textContent = 'Powered by Orisma Technology';

    // Append the logo div and span text to the anchor
    anchor.appendChild(logoDiv);
    anchor.appendChild(spanText);

    // Append the anchor to the footer
    footer.appendChild(anchor);

    // Get the main-page element and append the footer to it
    const mainPage = document.getElementById('main-page');
    mainPage.appendChild(footer);

}