export default {
    "/": {
        name: "",
        htmlPath: "home",
        cssPath: [],
    },
    "/login": {
        name: "login",
        htmlPath: "login",
        cssPath: ["login"],
        jsPath: ["login"],
    },
    "/forms": {
        name: "forms",
        htmlPath: "forms/index",
        cssPath: ["recentForm"],
        jsPath: ["recentForm","eventsource"],
    },
    "/inactive": {
        name: "formsInactive",
        htmlPath: "forms/inactive",
        cssPath: ["recentForm", "editForm"],
        jsPath: ["formInactive"],
    },
    "/forms/:id/edit": {
        name: "formsEdit",
        htmlPath: "forms/edit",
        cssPath: ["editForm"],
        jsPath: ["qrcode","easydropdown","signature_pad","quill", "draggable/draggable", "draggable/draggable_legacy", "draggable/sortable", "draggable/plugins", "editForm"],
    },
    "/forms/:id/viewform": {
        name: "formsView",
        htmlPath: "forms/edit",
        cssPath: ["editForm"],
        jsPath: ["easydropdown","signature_pad","quill", "editForm"],
    },
    "/forms/:id/formresponse": {
        name: "formResponse",
        htmlPath: "forms/response",
        cssPath: ["editForm"],
        jsPath: ["formResponse"],
    }
};