

export default async function (page) {
    const {name, htmlPath, cssPath, jsPath} = page;
    let renderNav = false;
    let next = true;
    if (name == "") {
        window.location.href = "/login";
        next = false
    } else if (!['login', '404'].includes(name)) {
        renderNav = true;
    }
    checkLogin(name)

    return {
        next: next,
        navbar: renderNav
    };
}

async function checkLogin(routeName)  {
    let token = localStorage.getItem("_token");
    if(token) {
        let session = parseJwt(token);
        let now = Math.floor(Date.now() / 1000);
        if(session.exp - now <= 0) {
            token = null;
            localStorage.removeItem('_token');
        }
    }
    
    if(token && routeName == 'login') {
        window.location.href = '/forms';
    } else if (!token && !['login', '404', "formsView", "formResponse", "formsInactive"].includes(routeName)) {
        window.location.href = '/login';
    }

  
    }